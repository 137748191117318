#art_posts_flex_container {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-flow: wrap;
}

.art_post {
  width: fit-content;
  height: fit-content;
  max-width: 100%;
  margin: 15px;
  box-shadow: none;
  cursor: pointer;
}

.art_img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 250px;
  border: 1px solid white;
}

.art_img:hover {
  box-shadow: 0px 0px 15px white;
}

#large_art_popup_graybox {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.8);
  display: flex;
  justify-content: center;
  z-index: 1;
  -webkit-animation: fadein .3s linear forwards;
  animation: fadein .3s linear forwards;
}

@-webkit-keyframes fadein {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
  
@keyframes fadein {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

#large_art_popup_container {
  max-width: 100%;
  max-height: 100%;
  margin: 20px;
  height: calc(100% - 110px);
  display: flex;
  align-items: center;
  justify-content: center;
}

%art_popup_btn {
  position: absolute;
  background-color: transparent;
  margin: 10px;
  border: 1px solid white;
  border-radius: 10px;
  cursor: pointer;  
}

#large_art_popup_close_btn {
  @extend %art_popup_btn;
  top: 0;
  right: 0;
  height: 60px;
  width: 60px;
}

#large_art_popup_next_btn, #large_art_popup_previous_btn {
  @extend %art_popup_btn;
  height: 200px;
  width: 60px;
}

#large_art_popup_next_btn {
  top: calc(50% - 100px);
  right: 0px;
}

#large_art_popup_previous_btn {
  top: calc(50% - 100px);
  left: 0px;
}

#large_art_img {
  max-width: inherit;
  max-height: inherit;
  height: auto;
}

#large_art_img:hover {
  box-shadow: none;
}

@media screen and (max-width: 600px) {
  #large_art_popup_next_btn, #large_art_popup_previous_btn {
    @extend %art_popup_btn;
    height: 60px;
    width: 60px;
  }
  
  #large_art_popup_next_btn {
    top: unset;
    bottom: 70px;
    right: 0px;
  }
  
  #large_art_popup_previous_btn {
    top: unset;
    bottom: 70px;
    left: 0px;
  }
}