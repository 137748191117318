#resume_content_container {
  margin: 20px auto auto auto;
  max-width: 1000px;
  display: grid;
  grid-template-columns: auto 270px;

  #skill_items {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 20px;
    align-items: center;
    justify-content: center;
    font-size: 17px;

    .skill_item {
      display: grid;
      grid-template-columns: 20px auto;
      width: 180px;
      max-width: 100%;
      grid-gap: 10px;
      align-items: center;
    }
  }
}

#resume_workplaces_container {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  grid-gap: 20px;

  .resume_workplace_container {
    width: calc(50% - 10px);
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}

#resume_btns {
  position: relative;
  display: flex;
  flex-flow: column;
  grid-gap: 10px;
  padding: 30px 20px 30px 20px;
  height: fit-content;
  border-left: 2px solid white;
  border-right: 2px solid transparent;
  background-image: linear-gradient(90deg, white, transparent),
    linear-gradient(90deg, white, transparent);
  background-size: 100% 2px;
  background-position: 0 0, 0 100%;
  background-repeat: no-repeat;

  .subgroup {
    display: grid;
    grid-template-columns: 50px auto;
    grid-template-rows: min-content;
    align-items: flex-end;
    font-size: 17px;
    height: fit-content;
    max-width: max-content;
  }

  .subgroup_square {
    margin: 0px 10px 2px 20px;
    width: 20px;
    height: 100%;
    border-left: 1px solid white;
    border-bottom: 1px solid white;
  }
}

#experience_header {
  padding: 10px;
  border-radius: 10px;
  width: 100%;
  margin-bottom: 20px;
  background-image: linear-gradient(
    to right,
    rgba(119, 94, 169, 0.7),
    rgba(121, 74, 165, 0.7)
  );
}

#resume_download_link {
  text-decoration: none;
}

#resume_download_link:hover {
  text-decoration: underline;
}

%resume_btn {
  background-color: transparent;
  color: white;
  border: none;
  font-family: sans-serif;
  font-size: 25px;
  font-weight: bold;
  cursor: pointer;
  text-align: left;
  padding: 0px;
}

%resume_btn:hover {
  text-decoration: underline;
}

.resume_btn_false,
.resume_btn_true {
  @extend %resume_btn;
}

.resume_btn_false {
  text-decoration: none;
}

.experience_btn:hover {
  text-decoration: none;
  cursor: initial;
}

%resume_content_text {
  background-image: linear-gradient(
    to right,
    rgba(119, 94, 169, 0.7),
    rgba(121, 74, 165, 0.7)
  );
  padding: 10px;
  width: calc(100% - 40px);
  border-radius: 10px;
  min-height: calc(100% - 20px);

  li {
    margin-bottom: 10px;
  }
}

.resume_content_text_true,
.resume_content_text_false {
  @extend %resume_content_text;
}

.resume_content_text_true {
  display: block;

  animation: 0.5s ease-out 0s 1 slideInFromBottom;
}

.resume_content_text_false {
  display: none;
  opacity: 0;
}

@keyframes slideInFromBottom {
  0% {
    transform: translateY(30px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@media screen and (max-width: 800px) {
  #resume_content_container {
    display: grid;
    grid-template-areas:
      "a"
      "b";
    grid-template-columns: 100%;
    grid-gap: 20px;
    margin: 10px;
  }

  #resume_btns {
    grid-area: a;
    width: calc(100% - 20px);
  }

  #resume_content_texts {
    grid-area: b;
    width: 100%;
    margin: 0;
  }

  .resume_content_text_true,
  .resume_content_text_false {
    width: calc(100% - 20px);
    margin: 0;
  }
}

.certifiate_container {
  margin: 20px 0px 20px 0px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);

  .certificate_link {
    padding: 7px;
    border-radius: 25px;
    margin-bottom: 20px;
    color: white;
    text-decoration: none;

    border: 1px solid white;
  }
}
