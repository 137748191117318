#case_study_nav_bar {
  display: flex;
  flex-flow: wrap;
  align-items: center;
  justify-content: center;
  grid-gap: 10px;
  margin: 10px;
  button {
    background-color: transparent;
    border: none;
    color: white;
    cursor: pointer;
    padding: 10px;
  }
  button:hover {
    text-decoration: underline;
  }
}

#case_study_nav_bar.fixed {
  position: fixed;
  top: 70px;
  width: calc(100% - 10px);
  background-color: #01020b;
  padding: 5px;
  margin: 0;
  grid-gap: 10px;
  border-bottom: 1px solid rgba(185, 124, 255, 0.4);
}

#case_study_container {
  width: 100%;
  #case_study_intro_img_container {
    margin-top: 40px;
    width: 100%;
    display: flex;
    justify-content: center;

    #case_study_intro_img {
      width: 800px;
      max-width: 100%;
    }
  }

  .case_study_large_img_container {
    width: 100%;
    margin-top: 100px;
    display: flex;
    .case_study_large_img {
      max-width: 100%;
      max-height: 1000px;
      margin: auto;
    }
  }

  h1 {
    width: calc(100% - 100px);
    padding: 50px;
    font-size: 60px;
    margin: 40px 0px 0px 0px;
  }

  h2 {
    font-size: 35px;
  }

  %case_study_section {
    width: calc(100% - 100px);
    background-image: linear-gradient(
      to right,
      rgba(141, 107, 181, 0.2),
      rgba(0, 0, 0, 0)
    );
    padding: 50px;
    margin-top: 50px;
  }

  .case_study_section_1_col {
    @extend %case_study_section;
    display: flex;
    grid-row-gap: 60px;
    flex-flow: column;
    justify-content: center;

    .case_study_col_subsection {
      max-width: 100%;
      width: 600px;
      //margin: auto;
    }

    .case_study_row_subsection {
      width: 100%;
    }
  }

  .case_study_section_2_col {
    @extend %case_study_section;
    display: grid;
    grid-template-columns: calc(50% - 30px) calc(50% - 30px);
    grid-column-gap: 60px;
    grid-row-gap: 40px;
    flex-flow: column;
    justify-content: center;

    .case_study_col_subsection {
      max-width: 100%;
      width: 700px;
      margin: 0px auto 0px auto;
    }

    .case_study_row_subsection {
      max-width: 100%;
      margin: 0px auto 0px auto;
    }
  }

  .case_study_col_subsection {
    display: flex;
    flex-flow: column;
    grid-gap: 20px;
    max-width: 100%;
    align-items: flex-start;

    h2,
    h3 {
      width: 100%;
      margin: 0px;
      max-width: min(1000px, 100%);
    }
  }

  .case_study_row_subsection {
    display: flex;
    flex-flow: row;
    grid-gap: 60px;
    max-width: calc(100% - 20px);
    align-items: center;
    justify-content: space-around;

    img {
      max-width: 60vw;
      max-height: 600px;
      margin: 0px;
    }

    h2,
    h3 {
      margin: auto;
      max-width: 100%;
    }
  }

  h3 {
    font-weight: 400;
    line-height: 30px;
  }

  #pain_points_container {
    display: flex;
    flex-flow: wrap;
    width: calc(100% - 100px);
    padding: 50px;
    grid-gap: 40px;
    justify-content: center;

    .pain_point {
      display: flex;
      align-items: center;
      text-align: center;
      flex-flow: column;
      width: 400px;
      max-width: 100%;

      .pain_point_number {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background-color: #9c6bb5;
        font-size: 40px;
      }

      h3 {
        font-size: 25px;
      }

      .pain_point_desc {
        font-size: 20px;
      }
    }
  }
}

@media screen and (max-width: 900px) {

  #case_study_nav_bar.fixed {
    top: 50px;
    grid-gap: 3px;
    button {
      padding: 3px;
    }
  }

  #case_study_container {
    text-align: center;

    h1 {
      font-size: 50px;
    }

    .case_study_col_subsection {
      align-items: center;
      margin: auto;
    }

    .case_study_section_2_col {
      width: calc(100% - 100px);
      display: grid;
      grid-template-columns: unset;
      grid-template-rows: auto;
      grid-column-gap: unset;
      grid-row-gap: 30px;

      .case_study_col_subsection {
        width: unset;
      }
    }

    .case_study_section_1_col {
      align-items: center;
    }

    .case_study_row_subsection {
      display: grid;
      grid-template-columns: 100%;
      grid-template-rows: auto;

      img {
        min-width: unset;
        max-width: 100%;
        margin: auto;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  #case_study_container {
    h1 {
      width: calc(100% - 40px);
      font-size: 50px;
      padding: 20px;
    }

    .case_study_section_1_col,
    .case_study_section_2_col {
      padding: 20px;
      width: calc(100% - 40px);
    }

    .case_study_section_2_col {
      .case_study_col_subsection {
        width: unset;
        margin: unset;
      }
    }

    .case_study_section_1_col {
      align-items: center;
    }

    .case_study_row_subsection {
      display: grid;
      grid-template-columns: 100%;
      grid-template-rows: auto;

      img {
        min-width: unset;
        max-width: 100%;
      }
    }

    #pain_points_container {
      width: calc(100% - 20px);
      padding: 10px;
    }
  }
}
