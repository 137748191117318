#landing_header_container {
  display: grid;
  grid-template-columns: 50% 50%;
  justify-content: center;
  background-image: linear-gradient(
    to right,
    rgba(141, 107, 181, 0.2),
    rgba(0, 0, 0, 0)
  );
  padding: 0px 0px 0px 30px;
  height: fit-content;
  min-height: 550px;
  margin-bottom: 150px;
}

#landing_header_txt_container {
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding: 0px 0px 0px 30px;
}

#landing_h1,
#landing_h3,
#landing_about_me_h1,
.ux_portfolio_project_h1 {
  font-family: "Rockwell";
}

#landing_h1_container {
  display: grid;
  grid-template-columns: 70px auto;
  grid-gap: 10px;
  justify-content: start;
  align-items: center;
  margin-top: 50px;
}

#landing_h1 {
  font-size: 60px;
  font-weight: 800;
  margin: 0px 0px -20px 0px;
  min-height: fit-content;
  animation: 0.8s ease-out 0s 1 slideInFromLeft;
}

#landing_h1_icon {
  max-height: 70px;
  max-width: 70px;
  animation: rotation 10s infinite linear;
}

#landing_h3 {
  font-size: 40px;
  font-weight: 500;
  min-height: fit-content;
  animation: 1s ease-out 0s 1 delayedFadeIn;
}

#landing_header_img_container {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}

#teila_img {
  position: absolute;
  z-index: 1;
  top: 30px !important;
  animation: rotation 50s infinite linear;
  max-width: inherit;
  max-height: 500px;
}

@keyframes rotation {
  from {
    transform: rotate(359.5deg);
  }
  to {
    transform: rotate(0deg);
  }
}

#contact_info_container {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  animation: 1s ease-out 0s 1 delayedFadeIn;
}

.contact_info {
  display: grid;
  grid-template-columns: 30px auto;
  align-items: center;
  margin-right: 30px;
  border-radius: 25px;
  background-image: linear-gradient(to right, #9c6bb5, #775ea9);
  padding: 5px 5px 5px 10px;
  margin-bottom: 10px;
  color: white;
  text-decoration: none;

  span {
    padding: 10px 10px 10px 0px;
  }
}

#landing_company_icons_container {
  display: flex;
  position: relative;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  height: 60px;
  margin-bottom: 250px;

  .company_logo,
  a {
    height: inherit;
    z-index: 1;
    opacity: 0.8;
    max-width: 200px;
    margin: 5px;
  }
}

#landing_about_me_container {
  display: flex;
  flex-flow: row;
  width: 100%;
  //grid-template-columns: auto 600px;
  grid-gap: 20px;
  align-items: center;

  #landing_about_me_text {
    margin: 40px;
    float: left;
    width: 60%;
  }

  #landing_about_me_h1 {
    margin-bottom: 30px;
  }

  #landing_about_me_h2 {
    font-weight: 300;
  }

  #landing_about_me_gradient_bg {
    position: relative;
    width: 40%;
    height: 300px;
    float: right;
    max-width: 50vw;
    border-radius: 150px 0 0 150px;
    background-image: linear-gradient(0.85turn, #6a379a, #363292);
  }

  %planet {
    position: absolute;
    border-radius: 50%;
  }

  /* @source: https://dev.to/afif/border-with-gradient-and-radius-387f */
  %planet_ring {
    position: absolute;
    border-radius: 50%;
    border: 3px solid transparent;
    background: linear-gradient(
        0deg,
        white,
        white,
        white,
        white,
        transparent,
        transparent
      )
      border-box;
    -webkit-mask: /*4*/ linear-gradient(#fff 0 0) padding-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }

  #landing_about_me_stars {
    position: absolute;
    height: 300px;
    top: 0px;
    left: 10%;
  }

  #landing_about_me_planet_1 {
    @extend %planet;
    width: 100px;
    height: 100px;
    top: -30px;
    left: 10%;
    background-image: linear-gradient(0.85turn, #306aa5, #4eb873);
    transform: rotate(40deg);

    #landing_about_me_planet_1_ring {
      @extend %planet_ring;
      top: 30px;
      left: calc(10% - 37px);
      width: 150px;
      height: 30px;
    }
  }

  #landing_about_me_planet_2 {
    @extend %planet;
    width: 140px;
    height: 140px;
    top: 20px;
    left: 60%;
    background-image: linear-gradient(0.85turn, #9e3c9f, #ebd067);
    transform: rotate(-20deg);

    #landing_about_me_planet_2_ring_1 {
      @extend %planet_ring;
      top: 50px;
      left: calc(40% - 88px);
      width: 200px;
      height: 30px;
    }

    #landing_about_me_planet_2_ring_2 {
      @extend %planet_ring;
      top: 51px;
      left: calc(40% - 111px);
      width: 250px;
      height: 39px;
      opacity: 0.5;
      border: 1px solid transparent;
    }
  }

  #landing_about_me_planet_3 {
    @extend %planet;
    width: 200px;
    height: 200px;
    top: 130px;
    left: 12%;
    background-image: linear-gradient(0.85turn, #4e8eb8, #f2f231);
    transform: rotate(30deg);

    #landing_about_me_planet_3_ring {
      @extend %planet_ring;
      top: 84px;
      left: calc(12% - 65px);
      width: 280px;
      height: 47px;
      border: 5px solid transparent;
    }
  }
}

#planet_1 {
  opacity: 1;
  z-index: 0;
  position: absolute;
  width: calc(680px * 1.4);
  height: calc(406px * 1.4);
  right: calc(0% - 300px);
  pointer-events: none;
}

#planet_2 {
  opacity: 1;
  z-index: -1;
  position: absolute;
  width: 300px;
  height: 300px;
  top: -50px;
  left: 20px;
  pointer-events: none;
}

#moon_1 {
  opacity: 1;
  z-index: 0;
  width: 30px;
  height: calc(203px * 1.4);
  position: absolute;
  -webkit-animation: xmotion 8s ease-in-out 0s infinite;
  animation: xmotion 8s ease-in-out 0s infinite;
}

#moon_1:before {
  content: "";
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-image: linear-gradient(0.85turn, #883c9f, #eba567);
  opacity: 1;
  z-index: 0;
  position: absolute;
  top: 100%;
  -webkit-animation: ymotion 8s ease-in-out 0s infinite;
  animation: ymotion 8s ease-in-out 0s infinite;
}

@keyframes xmotion {
  0% {
    right: -300px;
    z-index: -1;
    transform: scale(0.3);
  }

  50% {
    right: 500px;
    z-index: -1;
    transform: scale(0.5);
  }
  51% {
    right: 500px;
    z-index: 0;
    transform: scale(0.5);
  }

  100% {
    right: -300px;
    z-index: 0;
    transform: scale(2);
  }
}

@keyframes ymotion {
  0% {
    top: 0%;
  }
  50% {
    top: 80%;
  }
  100% {
    top: 20%;
  }
}

@media screen and (max-width: 900px) {
  #landing_about_me_container {
    display: flex;
    flex-flow: column;
  }

  #landing_about_me_gradient_bg {
    width: 100% !important;
    max-width: none !important;
  }

  #landing_about_me_text {
    margin: 20px !important;
    width: calc(100% - 40px) !important;
  }

  #contact_info_container {
    justify-content: center;
  }

  .contact_info {
    margin: 10px;
  }

  #body_content_container {
    margin-top: 0px;
  }

  #landing_h1_container {
    margin-top: 30px;
    justify-content: center;
    grid-template-columns: 70px max-content;
  }

  #landing_h1,
  #landing_h3 {
    text-align: center;
  }

  #landing_h3 {
    margin-bottom: 20px;
  }

  #teila_img {
    position: relative;
    top: 0;
    left: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    max-height: 300px;
  }

  @media screen and (max-width: 600px) {
    #landing_h1_icon {
      display: none;
    }

    #landing_h1_container {
      display: block;
    }
  }

  #landing_header_container {
    padding: 0px 20px 0px 20px;
    grid-template-columns: 100%;
  }

  #landing_header_txt_container {
    padding: 0px;
  }
}
