#footer {
  //position: absolute;
  //bottom: 0;
  width: calc(100% - 10px);
  background-color: rgb(26, 3, 49);
  height: fit-content;
  font-size: 15px;
  text-align: center;
  color: white;
  z-index: 2;
  margin-top: 45px;
  padding: 10px 5px 10px 5px;
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  grid-gap: 10px;

  p {
    margin: 0;
  }

  a {
    color: white;
    text-decoration: underline;
  }
}