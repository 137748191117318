#portfolio_projects {
  display: flex;
  width: calc(100% - 40px);
  justify-content: space-evenly;
  flex-flow: wrap;
  align-items: flex-start;
  row-gap: 60px;
  column-gap: 40px;
  margin: 20px;
}

.ux_portfolio_project_links_container {
  position: relative;

  height: 100%;
  max-width: 100%;
  margin: auto;
  width: fit-content;
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  border-radius: 20px;

  .ux_portfolio_project_thumbnail {
    width: 140px;
    max-width: calc(100vw - 20px);
    margin: 10px;
    border-radius: 10px;
    display: flex;
    flex-flow: column;
    text-align: center;
    cursor: pointer;
    transition: box-shadow 0.2s ease;
    background-image: linear-gradient(
      to right,
      rgba(119, 94, 169, 0.7),
      rgba(121, 74, 165, 0.7)
    );
    color: white;
    text-decoration: none;

    h4 {
      margin: 5px 10px 5px 10px;
    }

    img {
      display: flex;
      margin: auto;
      padding: 10px;
      max-height: 120px;
      max-width: calc(100% - 20px);
    }
  }

  .ux_portfolio_project_thumbnail:hover {
    box-shadow: 0px 0px 10px white;
  }
}

.ux_portfolio_project_container {
  display: flex;
  flex-flow: row;
  display: grid;
  grid-template-rows: 200px max-content;
  width: 600px;
  align-items: center;

  .ux_portfolio_project_text {
    margin: 10px;
    display: flex;
    flex-flow: column;
  }

  .ux_portfolio_project_h2 {
    margin: 10px 0 10px 0;
  }

  .ux_portfolio_project_h3 {
    margin: 0px 0 20px 0;
    font-weight: 300;
  }
}

.ux_project_content_container {
  position: relative;
  margin: 20px;
  padding: 20px;
  height: 100%;
  width: 100%;
}

.ux_project_content_header_container {
  width: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
}

.ux_project_thumbnail_container {
  display: flex;
  height: 100%;
  overflow: hidden;
  justify-content: center;
  align-items: baseline;
  position: relative;

  .ux_project_thumbnail {
    height: auto;
    width: 100%;
  }
}