#game_container {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

#canvas_container {
  position: relative;
  margin: auto;
  width: fit-content;
  height: fit-content;
}

#start_end_game_btns_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  justify-items: center;
  top: 0;
  margin: auto;
}

.start_end_game_btn {

  text-align: center;
  padding: 10px;
  margin: 5px;
  cursor: pointer;
  background-color: rgb(205, 175, 234);
  border: 2px solid rgb(119, 87, 149);
  border-radius: 5px;
  color: black;

}

.start_end_game_btn:hover, .player_move_btn:hover {
  background-color: rgb(119, 87, 149);
  border: 2px solid rgb(119, 87, 149);
}

#game_countdown_container {
  display: flex;
  flex-flow: column;
  height: 100px;
  max-width: 500px;
  justify-content: center;
  text-align: center;
  font-size: 40px;
  font-family: 'Courier New', Courier, monospace;
  font-weight: bold;
}