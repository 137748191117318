#body_content {
  position: fixed;
  top: 70px;
  height: calc(100% - 70px);
  width: 100%;
  z-index: 1;
  overflow-y: auto;
  overflow-x: hidden;
}

#body_content::-webkit-scrollbar {
  display: none;
}

%body_overlay {
  z-index: 0;
  position: fixed;
  height: 100%;
  width: 100%;
}

#body_overlay_1 {
  @extend %body_overlay;
  top: 0;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    #02041a,
    #0b153c,
    #3a145f
  );
}

#body_overlay_2 {
  @extend %body_overlay;
  bottom: 0;
  background-attachment: fixed;
  background-repeat: repeat;
  background-image: url("../public/stars.svg");
  background-size: 450px;
}

#body_overlay_3 {
  @extend %body_overlay;
  bottom: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#body_content_container {
  width: 100%;
  margin: auto;
  //padding-bottom: 10px;
  height: fit-content;
  border-radius: 0px 0px 10px 10px;

  .body_content_container_child {
    padding-bottom: 15px;
  }
}

.body_content_text {
  background-color: rgba(95, 71, 122, 0.8);
  padding: 10px;
  margin: 20px auto 10px auto;
  width: 80%;
  border-radius: 10px;

  ul {
    font-weight: lighter;
  }

  p {
    margin: 15px;
    text-align: center;
    font-size: 110%;
  }

  hr {
    margin-left: 0;
    position: relative;
    border-top: 1px solid white;
    border-bottom: none;
    margin-bottom: 15px;
    width: 50%;
    min-width: 200px;
  }

  hr:before,
  hr:after {
    content: " ";
    width: 10px;
    height: 10px;
    position: absolute;
    transform: rotate(45deg);
    border: 1px solid white;
    background-color: white;
  }

  hr:before {
    left: -5px;
    bottom: -5px;
  }

  hr:after {
    bottom: -5px;
    right: -5px;
  }

  li {
    margin-bottom: 5px;
  }
}

.purple_btn {
  border-radius: 25px;
  background-image: linear-gradient(to right, #9c6bb5, #775ea9);
  padding: 15px;
  margin-bottom: 10px;
  color: white;
  text-decoration: none;
  width: fit-content;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(10%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes delayedFadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 900px) {
  #body_content {
    top: 50px;
    height: calc(100% - 50px);
  }

  .body_content_text {
    width: auto;
    border-radius: 0;
  }
}
