#header {
  position: fixed;
  z-index: 2;
  top: 0;
  display: flex;
  justify-content: left;
  background-color: rgba(185, 124, 255, 0.4);
  width: 100%;
  height: 70px;
  align-items: center;
  p {
    margin: 0;
  }
}

%border-purple {
  border: 1px solid #c3b4dd;
  border-radius: 15px;
}

%btn-gradient {
  background-image: linear-gradient(to right, #8d6bb5, #6a5ea9);
}

#hamburger_container {
  @extend %border-purple;
  @extend %btn-gradient;
  display: none;
  margin-left: 20px;
  height: 50px;
  width: 50px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

%row-flex {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.nav_btn {

  @extend %row-flex;
  color: white;
  padding: 10px;
  margin-left: 20px;
}

#links_container {
  @extend %row-flex;
  height: 100%;

  a {
    text-decoration: none;
    margin-right: 5px;
    p {
      margin: 0;
      padding-left: 10px;
    }
  }
}

@media screen and (max-width: 900px) {
  #header {
    height: 50px;
  }

  #hamburger_container {
    display: flex;
    margin-left: 10px;
    height: 40px;
    width: 40px;
    border-radius: 10px;
  }

  #links_container {
    position: fixed;
    display: grid;
    z-index: 2;
    grid-template-rows: 50px 50px 50px 50px 50px;
    grid-gap: 20px;
    padding: 20px 0px 0px 0px;
    background-color: rgb(64, 44, 87);
    top: 50px;
    width: 200px;
    height: calc(100% - 70px);
    transition: left 0.3s ease;
    a {
      margin-left: 0px;

      p {
        margin: 0;
      }
    }
  }
}