#body_content_header_container {
  display: grid;
  grid-template-columns: calc(100% - 340px) 340px;
  justify-content: center;
  background-image: linear-gradient(
    to right,
    rgba(141, 107, 181, 0.2),
    rgba(0, 0, 0, 0)
  );
  padding: 0px 0px 0px 30px;
  height: fit-content;
  min-height: 300px;
}

.body_content_header {
  display: flex;
  flex-flow: column;
  align-items: center;
  text-align: center;
  width: 100%;
  height: fit-content;
  background-image: linear-gradient(to right, rgba(141, 107, 181, 0.2), transparent);

  div {
    flex: 1;
  }
}

#body_content_h1 {
  font-size: 60px;
  font-weight: 800;
  margin: 0px 0px -20px 0px;
  min-height: -moz-fit-content;
  min-height: fit-content;
  animation: 1s ease-out 0s 1 slideInFromLeft;
}

#body_content_h1, #body_content_h4 {
  font-family: 'Rockwell';
}

#body_content_h4 {
  font-size: 30px;
  font-weight: 500;
  min-height: -moz-fit-content;
  min-height: fit-content;
  margin-bottom: 20px;
  margin-top: 20px;
  animation: 1s ease-out 0s 1 delayedFadeIn;

}

.header_img_container {
  display: flex;
  align-items: center;
  justify-content: right;
  img {
    height: 300px;
    padding: 20px;
    max-width: 100%;
    max-height: 100%;
  }
}

.body_content_header_txt_container {
  padding: 20px;
  display: flex;
  flex-flow: column;
  justify-content: center;

  h1 {
    font-size: 50px;
    margin-bottom: 0px;
    margin-top: 20px;
  }
}

@media screen and (max-width: 900px) {
  .header_img_container {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      height: 300px;
      padding: 0px;
      max-width: 100vw;
      max-height: 100vw;
    }
  }

  #body_content_h4 {
    text-align: center;
  }

  #body_content_header_container {
    padding: 0px 20px 0px 20px;
    grid-template-columns: 100%;
  }
}

@media screen and (max-width: 600px) {
  #body_content_h1 {
    text-align: center;
  }
}
